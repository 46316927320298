import React from 'react';
import { graphql } from 'gatsby';
import { Query } from 'react-apollo';
import tw from 'tailwind.macro';
import styled from 'styled-components';
import gql from 'graphql-tag';
import logger from 'utils/logger';
import Fade from 'react-reveal/Fade';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { Menu } from 'components/Navigation';
import Layout from 'components/Layout';
import SEO from 'components/seo';

import { Container, Row } from 'components/GridSystem';
import { CallToActionPreFooter } from 'components/CallToActions';
import {
  // BusinessFunction,
  SectionLabel,
  SectionTitle
} from 'components/Text';
import { CapabilityCard } from 'components/Cards';

import { Button } from 'components/Elements';

import {
  CoverNotFound,
} from 'components/Sections';

class Page extends React.Component {
  render() {
    const {
      data,
      location
    } = this.props;
    logger.gql(data);
    logger.template(__filename, '>', 'render()');
    // const siteTitle = data.site.siteMetadata.title;

    const seoProps = {
      title: `Not Found`,
      description: `` //description && description.childMdx.rawBody
    };
    const animationDuration = 600;
    logger.templateEnd();
    return (
      <Layout location={location} title={seoProps.title}>
        <SEO {...seoProps} />
        <Menu colorScheme={`dark`} />
        <Fade>
          <CoverNotFound
            bgColor={`gray-800`}
          />
          <CallToActionPreFooter
            bgColor={`gray-700`}
            title={`Have a challenge you’d like us to explore?`}
            callToAction={`Get in touch!`}
            uri={``}
            to={`/contact`}
            utm={{
              campaign: `t`
            }}
          />
        </Fade>
      </Layout>
    );
  }
}

export default Page;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
